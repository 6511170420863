import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  TextField,
  Grid,
  Button,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import SizedBox from "../SizedBox";
import CurrencyTextField from "../CurrencyTextField";
import { Formik } from "formik";
import API from "../../services/api";
import { getInteger } from "../../Common/helperMethods";
import { Alert, AlertTitle } from "@material-ui/lab";
import DisplayFiles from "../Common/displayFiles";

const CG_API_URL = process.env.REACT_APP_CG_CONTROLLER;

function CG({
  activeQuarter,
  setSelectedTab,
  client,
  showToast,
  isOtherTabVisible,
  role,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    ["CG_Data", id],
    async () => {
      return API.get(CG_API_URL + `GetCapitalGains/${id}`)
        .then((res) => res.data)
        .catch((e) => console.log("API FAILED", e));
    },
    {
      retry: false,
      cacheTime: Infinity,
    }
  );

  const isDisabled = client?.isDisabled;
  const isClient = role === "Client";

  const cg_Data = data
    ? data
    : {
        shortTermCG_15: 0,
        shortTermCG_Marginal: 0,
        lossBroughtForward: 0,
        netTaxableShortTerm: 0,
        longTermCG_10: 0,
        longTermCG_20: 0,
        exemption: 0,
        longLossBroughtForward: 0,
        netTaxableLongTerm: 0,
        netTaxableGain: 0,
        taxOnCG: 0,
        totalLossBroughtForward: 0,
        comment: "",
        taxQtrProfileId: id,
        capitalGainId: "00000000-0000-0000-0000-000000000000",
        fileList: [],
      };

  function getNetTaxableTerm15_Intra(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal
  ) {
    const st15 = getInteger(shortTermCG_15);
    const st30 = getInteger(shortTermCG_Marginal);
    const st20 = getInteger(shortTermCG_20);

    if (st15 <= 0) {
      return Math.min(Math.max(0, st20 + st30) + st15, 0);
    } else {
      return Math.max(Math.min(0, st20 + st30) + st15, 0);
    }
  }

  function getNetTaxableTerm20_Intra(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal
  ) {
    const st15 = getInteger(shortTermCG_15);
    const st30 = getInteger(shortTermCG_Marginal);
    const st20 = getInteger(shortTermCG_20);

    if (st20 < 0) {
      if (st15 < 0) {
        return Math.max(Math.min(st20 + st30, 0), st20);
      } else {
        return Math.min(Math.max(0, st15 + st30) + st20, 0);
      }
    } else {
      if (st15 < 0) {
        return Math.max(Math.min(0, st15 + st30) + st20, 0);
      } else {
        return Math.min(st20, Math.max(st20 + st30, 0));
      }
    }
  }

  function getNetTaxableTerm30_Intra(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal
  ) {
    const st15 = getInteger(shortTermCG_15);
    const st20 = getInteger(shortTermCG_20);
    const st30 = getInteger(shortTermCG_Marginal);

    if (st30 < 0) {
      if (st15 < 0) {
        console.log(
          "hello",
          Math.max(Math.min(st20 + st30, 0), st30),
          st30,
          st15
        );
        return Math.max(Math.min(st20 + st30, 0), st30);
      } else {
        return Math.min(Math.max(st30, Math.min(st20 + st30, 0)) + st15, 0);
      }
    } else {
      if (st15 < 0) {
        return Math.max(Math.min(st30, Math.max(st20 + st30, 0)) + st15, 0);
      } else {
        return Math.min(st30, Math.max(st20 + st30, 0));
      }
    }
  }

  // function getNetTaxableTerm_Intra(
  //   shortTermCG_15,
  //   shortTermCG_Marginal,
  //   isNST15
  // ) {
  //   const st15 = getInteger(shortTermCG_15);
  //   const st30 = getInteger(shortTermCG_Marginal);
  //   if (st15 >= 0 && st30 >= 0) {
  //     return isNST15 ? st15 : st30;
  //   } else if (st15 < 0 && st30 >= 0) {
  //     return isNST15 ? 0 : st15 + st30;
  //   } else if (st15 >= 0 && st30 < 0) {
  //     return isNST15 ? st15 + st30 : 0;
  //   } else if (st15 < 0 && st30 < 0) {
  //     return isNST15 ? 0 : st15 + st30;
  //   }
  //   return 0;
  // }

  function getNetTaxableTerm15_Inter(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    isShortTerm
  ) {
    let intraStcg15 = getNetTaxableTerm15_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg20 = getNetTaxableTerm20_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg30 = getNetTaxableTerm30_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );

    let intraLtcg10 = getNetTaxableTerm15_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg12_5 = getNetTaxableTerm20_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg20 = getNetTaxableTerm30_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );

    let sumShort = intraStcg15 + intraStcg20 + intraStcg30;

    let sumLong = intraLtcg10 + intraLtcg12_5 + intraLtcg20;

    if (isShortTerm) {
      if (sumShort >= 0) {
        return intraStcg15;
      } else {
        if (sumLong >= 0) {
          return Math.max(
            Math.min(intraStcg15 + intraStcg20 + intraStcg30 + sumLong, 0),
            intraStcg15
          );
        } else {
          return intraStcg15;
        }
      }
    } else {
      if (sumLong <= 0) {
        return intraLtcg10;
      } else {
        if (sumShort < 0) {
          return Math.max(
            Math.min(
              intraLtcg10 + intraLtcg12_5 + intraLtcg20 + sumShort,
              intraLtcg10
            ),
            0
          );
        } else {
          return intraLtcg10;
        }
      }
    }
  }

  function getNetTaxableTerm20_Inter(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    isShortTerm
  ) {
    let intraStcg15 = getNetTaxableTerm15_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg20 = getNetTaxableTerm20_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg30 = getNetTaxableTerm30_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );

    let intraLtcg10 = getNetTaxableTerm15_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg12_5 = getNetTaxableTerm20_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg20 = getNetTaxableTerm30_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );

    let sumShort = intraStcg15 + intraStcg20 + intraStcg30;

    let sumLong = intraLtcg10 + intraLtcg12_5 + intraLtcg20;

    if (isShortTerm) {
      if (sumShort >= 0) {
        return intraStcg20;
      } else {
        if (sumLong >= 0) {
          return Math.max(
            Math.min(intraStcg20 + intraStcg30 + sumLong, 0),
            intraStcg20
          );
        } else {
          return intraStcg20;
        }
      }
    } else {
      if (sumLong <= 0) {
        return intraLtcg12_5;
      } else {
        if (sumShort < 0) {
          return Math.max(
            Math.min(intraLtcg12_5 + intraLtcg20 + sumShort, intraLtcg12_5),
            0
          );
        } else {
          return intraLtcg12_5;
        }
      }
    }
  }

  function GetInterSum(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20
  ) {
    const stcgInter30 = getNetTaxableTerm30_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      true
    );
    const stcgInter15 = getNetTaxableTerm15_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      true
    );
    const stcgInter20 = getNetTaxableTerm20_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      true
    );
    const stcgSum = stcgInter15 + stcgInter20 + stcgInter30;

    const ltcgInter20 = getNetTaxableTerm30_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      false
    );
    const ltcgInter10 = getNetTaxableTerm15_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      false
    );
    const ltcgInter12_5 = getNetTaxableTerm20_Inter(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      false
    );
    const ltcgSum = ltcgInter10 + ltcgInter12_5 + ltcgInter20;
    return {
      stcgInter: {
        stcgInter15,
        stcgInter20,
        stcgInter30,
        stcgSum,
      },
      ltcgInter: {
        ltcgInter10,
        ltcgInter12_5,
        ltcgInter20,
        ltcgSum,
      },
    };
  }

  function getNetTaxableTerm30_Inter(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    isShortTerm
  ) {
    let intraStcg15 = getNetTaxableTerm15_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg20 = getNetTaxableTerm20_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );
    let intraStcg30 = getNetTaxableTerm30_Intra(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal
    );

    let intraLtcg10 = getNetTaxableTerm15_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg12_5 = getNetTaxableTerm20_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    let intraLtcg20 = getNetTaxableTerm30_Intra(
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );

    let sumShort = intraStcg15 + intraStcg20 + intraStcg30;

    let sumLong = intraLtcg10 + intraLtcg12_5 + intraLtcg20;

    if (isShortTerm) {
      if (sumShort >= 0) {
        return intraStcg30;
      } else {
        if (sumLong >= 0) {
          return Math.max(Math.min(intraStcg30 + sumLong, 0), intraStcg30);
        } else {
          return intraStcg30;
        }
      }
    } else {
      if (sumLong <= 0) {
        return intraLtcg20;
      } else {
        if (sumShort < 0) {
          return Math.max(
            Math.min(intraLtcg20 + intraLtcg20 + sumShort, intraLtcg20),
            0
          );
        } else {
          return intraLtcg20;
        }
      }
    }
  }
  function getShortGain15(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf=getInteger(shortBf);
    const ltcgBf=getInteger(longBf);
    const longStcg30 = getShortGain30(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      stcgBf,
      ltcgBf
    );
    if (longStcg30 < 0) {
      return 0;
    }
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    console.log("ddd", interSum);
    const sum1 = stcgBf + interSum.stcgInter.stcgSum;
    if (sum1 < 0) {
      return 0;
    }
    console.log("ddd1", sum1);

    const maxValue = Math.max(sum1, 0);
    console.log("ddd2", maxValue);
    return Math.min(maxValue, interSum.stcgInter.stcgInter15);
  }

  function getShortGain20(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf = getInteger(shortBf);
    const ltcgBf = getInteger(longBf);
    const longStcg30 = getShortGain30(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20,
      stcgBf,
      ltcgBf
    );
    if (longStcg30 < 0) {
      return 0;
    }
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    const sum1 = stcgBf + interSum.stcgInter.stcgSum;
    if (sum1 < 0) {
      return 0;
    }

    const sum2 =
      stcgBf + interSum.stcgInter.stcgInter30 + interSum.stcgInter.stcgInter20;
    return Math.min(Math.max(sum2, 0), interSum.stcgInter.stcgInter20);
  }

  function getShortGain30(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf = getInteger(shortBf);
    const ltcgBf = getInteger(longBf);
    console.log("ss1");
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    console.log("ss0", interSum);
    const sum1 = stcgBf + interSum.stcgInter.stcgSum;
    console.log("ss1", sum1);
    const sum2 = ltcgBf + interSum.ltcgInter.ltcgSum;
    console.log("ss2", sum2);
    const sum3 =
      stcgBf + interSum.stcgInter.stcgSum + ltcgBf + interSum.ltcgInter.ltcgSum;
    console.log("ss3", sum3);
    if (sum1 < 0) {
      if (sum2 > 0) {
        return sum3 > 0 ? 0 : sum3;
      } else {
        return sum1;
      }
    } else {
      return Math.max(stcgBf + interSum.stcgInter.stcgInter30, 0);
    }
  }

  function getLongGain10(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf = getInteger(shortBf);
    const ltcgBf = getInteger(longBf);
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );

    if (ltcgBf <= 0) {
      if (interSum.ltcgInter.ltcgSum <= 0) {
        return 0;
      }

      const sum1 = ltcgBf + interSum.ltcgInter.ltcgSum;
      if (sum1 >= 0) {
        const innerMin = Math.min(stcgBf + interSum.stcgInter.stcgSum, 0);
        const sum2 = interSum.ltcgInter.ltcgSum + ltcgBf + innerMin;
        return Math.min(Math.max(sum2, 0), interSum.ltcgInter.ltcgInter10);
      }

      return 0;
    }

    return interSum.ltcgInter.ltcgInter10;
  }

  function getLongGain12_5(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf = getInteger(shortBf);
    const ltcgBf = getInteger(longBf);
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );

    if (ltcgBf <= 0) {
      if (interSum.ltcgInter.ltcgSum <= 0) {
        return 0;
      }

      const sum1 = ltcgBf + interSum.ltcgInter.ltcgSum;
      if (sum1 >= 0) {
        const innerMin = Math.min(stcgBf + interSum.stcgInter.stcgSum, 0);
        const sum2 =
          interSum.ltcgInter.ltcgInter12_5 +
          interSum.ltcgInter.ltcgInter20 +
          ltcgBf +
          innerMin;
        return Math.min(Math.max(sum2, 0), interSum.ltcgInter.ltcgInter12_5);
      }

      return 0;
    }

    return interSum.ltcgInter.ltcgInter12_5;
  }

  function getLongGain20(
    shortTermCG_15,
    shortTermCG_20,
    shortTermCG_Marginal,
    longTermCG_10,
    longTermCG_12_5,
    longTermCG_20,
    shortBf,
    longBf
  ) {
    const stcgBf = getInteger(shortBf);
    const ltcgBf = getInteger(longBf);
    const interSum = GetInterSum(
      shortTermCG_15,
      shortTermCG_20,
      shortTermCG_Marginal,
      longTermCG_10,
      longTermCG_12_5,
      longTermCG_20
    );
    if (ltcgBf <= 0) {
      if (interSum.ltcgInter.ltcgSum <= 0) {
        return ltcgBf + interSum.ltcgInter.ltcgSum;
      }

      const sum1 = ltcgBf + interSum.ltcgInter.ltcgSum;
      if (sum1 >= 0) {
        const innerMin = Math.min(stcgBf + interSum.stcgInter.stcgSum, 0);
        return Math.max(interSum.ltcgInter.ltcgInter20 + ltcgBf + innerMin, 0);
      }

      return Math.max(
        ltcgBf + interSum.ltcgInter.ltcgSum,
        ltcgBf + interSum.ltcgInter.ltcgInter20
      );
    }

    return interSum.ltcgInter.ltcgInter20;
  }

  if (isLoading) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
        size={60}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={cg_Data}
        onSubmit={async (values, actions) => {
          API.post(CG_API_URL + "AddCapitalGains", {
            ...values,
            tabName: "CG",
            shortTermCG_15: getInteger(values.shortTermCG_15),
            shortTermCG_Marginal: getInteger(values.shortTermCG_Marginal),
            lossBroughtForward: getInteger(values.lossBroughtForward),
            totalLossBroughtForward: getInteger(values.totalLossBroughtForward),
            longTermCG_10: getInteger(values.longTermCG_10),
            longTermCG_20: getInteger(values.longTermCG_20),
            shortTermCG_20: getInteger(values.shortTermCG_20),
            longTermCG_12_5: getInteger(values.longTermCG_12_5),
            longLossBroughtForward: getInteger(values.longLossBroughtForward),
            exemption: getInteger(values.exemption),
            taxOnCG: getInteger(values.taxOnCG),
            intraNetTaxableShortTerm_15: getNetTaxableTerm15_Intra(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal
            ),
            intraNetTaxableShortTermNormal: getNetTaxableTerm30_Intra(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal
            ),

            intraNetTaxableLongTerm_10: getNetTaxableTerm15_Intra(
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20
            ),
            intraNetTaxableLongTerm_20: getNetTaxableTerm20_Intra(
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20
            ),

            interNetTaxableShortTerm_15: getNetTaxableTerm15_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              true
            ),
            interNetTaxableShortTermNormal: getNetTaxableTerm30_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              true
            ),
            interNetTaxableLongTerm_10: getNetTaxableTerm15_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              false
            ),
            interNetTaxableLongTerm_20: getNetTaxableTerm30_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              false
            ),

            netTaxableGainShort_15AfterSetOff: getShortGain15(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
            netTaxableGainShort_NormalAfterSetOff: getShortGain30(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
            netTaxableGainLong_10AfterSetOff: getLongGain10(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
            netTaxableGainLong_20AfterSetOff: getLongGain20(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
            netTaxableShortTerm20: getNetTaxableTerm20_Intra(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal
            ),
            interNetTaxableShortTerm_20: getNetTaxableTerm20_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              true
            ),
            intraNetTaxableLongTerm_12_5: getNetTaxableTerm20_Intra(
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20
            ),
            interNetTaxableLongTerm_12_5: getNetTaxableTerm20_Inter(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              false
            ),
            netTaxableGainShort_20AfterSetOff: getShortGain20(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
            netTaxableGainLong_12_5AfterSetOff: getLongGain12_5(
              values.shortTermCG_15,
              values.shortTermCG_20,
              values.shortTermCG_Marginal,
              values.longTermCG_10,
              values.longTermCG_12_5,
              values.longTermCG_20,
              values.lossBroughtForward,
              values.longLossBroughtForward
            ),
          }).then((response) => {
            showToast({
              message: "Capital Gain submitted successfully!",
              severity: "success",
            });
            setSelectedTab(5);
            navigate(`/advance-tax/quarter/${id}/others`);
          });
        }}
      >
        {(formikProps) => (
          <>
            <SizedBox height={10} width={10}></SizedBox>
            <Grid container>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>
                    <Typography>
                      Please upload your Capital Gains documents here.
                    </Typography>
                  </AlertTitle>
                </Alert>
              </Grid>
            </Grid>

            <SizedBox height={30} width={10}></SizedBox>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_15"
                      label="Short Term CG at 15%"
                      fullWidth
                      disabled={isClient}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "shortTermCG_15",
                          e.target.value
                        );
                      }}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.shortTermCG_15}
                      error={
                        formikProps.touched.shortTermCG_15 &&
                        !!formikProps.errors.shortTermCG_15
                      }
                      helperText={
                        formikProps.touched.shortTermCG_15 &&
                        formikProps.errors.shortTermCG_15
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_10"
                      label="Long term CG at 10%"
                      fullWidth
                      disabled={isClient}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.longTermCG_10}
                      error={
                        formikProps.touched.longTermCG_10 &&
                        !!formikProps.errors.longTermCG_10
                      }
                      helperText={
                        formikProps.touched.longTermCG_10 &&
                        formikProps.errors.longTermCG_10
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_20"
                      label="Short Term CG at 20%"
                      fullWidth
                      disabled={isClient}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "shortTermCG_20",
                          e.target.value
                        );
                      }}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.shortTermCG_20}
                      error={
                        formikProps.touched.shortTermCG_20 &&
                        !!formikProps.errors.shortTermCG_20
                      }
                      helperText={
                        formikProps.touched.shortTermCG_20 &&
                        formikProps.errors.shortTermCG_20
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_12_5"
                      label="Long term CG at 12.5%"
                      fullWidth
                      disabled={isClient}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={formikProps.values.longTermCG_12_5}
                      error={
                        formikProps.touched.longTermCG_12_5 &&
                        !!formikProps.errors.longTermCG_12_5
                      }
                      helperText={
                        formikProps.touched.longTermCG_12_5 &&
                        formikProps.errors.longTermCG_12_5
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="shortTermCG_Marginal"
                      label="Short Term CG at marginal rates"
                      disabled={isClient}
                      fullWidth
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.shortTermCG_Marginal}
                      error={
                        formikProps.touched.shortTermCG_Marginal &&
                        !!formikProps.errors.shortTermCG_Marginal
                      }
                      helperText={
                        formikProps.touched.shortTermCG_Marginal &&
                        formikProps.errors.shortTermCG_Marginal
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="longTermCG_20"
                      label="Long term CG at 20%"
                      disabled={isClient}
                      fullWidth
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.longTermCG_20}
                      error={
                        formikProps.touched.longTermCG_20 &&
                        !!formikProps.errors.longTermCG_20
                      }
                      helperText={
                        formikProps.touched.longTermCG_20 &&
                        formikProps.errors.longTermCG_20
                      }
                      //disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                </Grid>

                <SizedBox height={30} width={10}></SizedBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTerm"
                      label="Intra - Net short term gain 15%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm15_Intra(
                        formikProps.values.shortTermCG_15,
                        formikProps.values.shortTermCG_20,
                        formikProps.values.shortTermCG_Marginal
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableLongTerm"
                      label="Intra - Net long term gain 10%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm15_Intra(
                        formikProps.values.longTermCG_10,
                        formikProps.values.longTermCG_12_5,
                        formikProps.values.longTermCG_20
                      )}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTerm20"
                      label="Intra - Net short term gain 20%"
                      disabled={true}
                      fullWidth
                      //onChange={formikProps.handleChange}
                      //onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm20_Intra(
                        formikProps.values.shortTermCG_15,
                        formikProps.values.shortTermCG_20,
                        formikProps.values.shortTermCG_Marginal
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="intraNetTaxableLongTerm_12_5"
                      label="Intra - Net long term gain 12.5%"
                      disabled={true}
                      fullWidth
                      //onChange={formikProps.handleChange}
                      //onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm20_Intra(
                        formikProps.values.longTermCG_10,
                        formikProps.values.longTermCG_12_5,
                        formikProps.values.longTermCG_20
                      )}
                    />
                  </Grid>
                </Grid>
                <SizedBox height={30} width={10}></SizedBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableShortTermNormal"
                      label="Intra - Net short term gain normal"
                      disabled={true}
                      fullWidth
                      // onChange={formikProps.handleChange}
                      // onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm30_Intra(
                        formikProps.values.shortTermCG_15,
                        formikProps.values.shortTermCG_20,
                        formikProps.values.shortTermCG_Marginal
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <CurrencyTextField
                      id="netTaxableLongTerm"
                      label="Intra - Net long term gain 20%"
                      disabled={true}
                      fullWidth
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      maximumValue="1000000000"
                      minimumValue="-999999999"
                      value={getNetTaxableTerm30_Intra(
                        formikProps.values.longTermCG_10,
                        formikProps.values.longTermCG_12_5,
                        formikProps.values.longTermCG_20
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {!isDisabled && (
                  <Grid item xs={12} sm={12} md={12}>
                    <DisplayFiles
                      key="Adv_CapitalGains"
                      {...{
                        formikProps,
                        type: "Adv_CapitalGains",
                        buttonText: "Upload File",
                        isClient,
                      }}
                    ></DisplayFiles>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <SizedBox height={30}></SizedBox>
                  <TextField
                    id="comment"
                    variant="outlined"
                    multiline={true}
                    label="Enter your comments/notes here"
                    type="text"
                    name="comment"
                    minRows={5}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.comment}
                    error={
                      formikProps.touched.comment &&
                      !!formikProps.errors.comment
                    }
                    helperText={
                      formikProps.touched.comment && formikProps.errors.comment
                    }
                    disabled={isDisabled || formikProps.isSubmitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <SizedBox height={30}></SizedBox>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disabled={isDisabled || formikProps.isSubmitting}
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableShortTermIntra"
                    label="Inter - Net short term gain 15%"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm15_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableLongTermIntra"
                    label="Inter - Net long term gain 10%"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    //value={formikProps.values.netTaxableLongTerm}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm15_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      false
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableShortTerm_20"
                    label="Inter - Net short term gain 20%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm20_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableLongTerm_12_5"
                    label="Inter - Net long term gain 12.5%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    //value={formikProps.values.netTaxableLongTerm}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm20_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      false
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableShortTermNormalIntra"
                    label="Inter - Net short term gain normal"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm30_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      true
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="interNetTaxableLongTerm_20"
                    label="Inter - Net long term gain 20%"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getNetTaxableTerm30_Inter(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      false
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="lossBroughtForward"
                    label="Short Term Loss B/f"
                    disabled={isClient}
                    fullWidth
                    maximumValue="0"
                    minimumValue="-999999999"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.lossBroughtForward}
                    error={
                      formikProps.touched.lossBroughtForward &&
                      !!formikProps.errors.lossBroughtForward
                    }
                    helperText={
                      formikProps.touched.lossBroughtForward &&
                      formikProps.errors.lossBroughtForward
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="longLossBroughtForward"
                    label="Long Term Loss B/f"
                    disabled={isClient}
                    fullWidth
                    maximumValue="0"
                    minimumValue="-999999999"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.longLossBroughtForward}
                    error={
                      formikProps.touched.longLossBroughtForward &&
                      !!formikProps.errors.longLossBroughtForward
                    }
                    helperText={
                      formikProps.touched.longLossBroughtForward &&
                      formikProps.errors.longLossBroughtForward
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="totalLossBroughtForward"
                    label="Total Loss B/f"
                    disabled={true}
                    fullWidth
                    maximumValue="999999999"
                    minimumValue="0"
                    value={formikProps.values.totalLossBroughtForward}
                    error={
                      formikProps.touched.totalLossBroughtForward &&
                      !!formikProps.errors.totalLossBroughtForward
                    }
                    helperText={
                      formikProps.touched.totalLossBroughtForward &&
                      formikProps.errors.totalLossBroughtForward
                    }
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGain"
                    label="Net Short Term Gain 15% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain15(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longlossBroughtForward
                    )}
                  />
                  {/* <Typography style={{ color: "red", fontSize: 12 }}>
                      {msg}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLong"
                    label="Net Long Term Gain 10% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain10(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainShort_20AfterSetOff"
                    label="Net Short Term Gain 20% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain20(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longlossBroughtForward
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLong_12_5AfterSetOff"
                    label="Net Long Term Gain 12.5% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    //onChange={formikProps.handleChange}
                    //onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain12_5(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward
                    )}
                  />
                </Grid>
              </Grid>

              <SizedBox height={30} width={10}></SizedBox>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainNormalAfterSetoff"
                    label="Net Short Term Gain Normal after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getShortGain30(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward
                    )}
                  />
                  {/* <Typography style={{ color: "red", fontSize: 12 }}>
                      {msg}
                    </Typography> */}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <CurrencyTextField
                    id="netTaxableGainLongAfterSetOff"
                    label="Net Long Term Gain 20% after set-off of b/f"
                    disabled={true}
                    fullWidth
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    maximumValue="1000000000"
                    minimumValue="-999999999"
                    value={getLongGain20(
                      formikProps.values.shortTermCG_15,
                      formikProps.values.shortTermCG_20,
                      formikProps.values.shortTermCG_Marginal,
                      formikProps.values.longTermCG_10,
                      formikProps.values.longTermCG_12_5,
                      formikProps.values.longTermCG_20,
                      formikProps.values.lossBroughtForward,
                      formikProps.values.longLossBroughtForward
                    )}
                  />
                </Grid>
              </Grid>
              <SizedBox height={30} width={10}></SizedBox>
            </Grid>
          </>
        )}
      </Formik>
    </div>
  );
}
export default CG;
